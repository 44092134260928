@use '@/styles/utils/mixins.scss' as *;

.videoWrapper {
  position: relative;
  width: 100%;
  height: 35.75rem;

  @include mob() {
    height: 9.375rem;
  }

  .videoPlayer {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    &Controller {
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: flex-end;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;

      &:hover {
        opacity: 0.9;
      }
      @media screen and (max-width: $minTablet) {
        bottom: 3.125rem;
      }
    }
    &Frame {
      height: 100%;
      width: 100%;
      max-width: unset;
      object-fit: cover;
      object-position: center;
    }
  }

  .videoBtn {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 3.75rem;
    width: 3.75rem;
    padding: 0;

    @include mob() {
      height: 2rem;
      width: 2rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
